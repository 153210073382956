import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { VisualType, VisualVariant } from 'common/Button';
import BannerImage from 'components/Banner/BannerImage';
import LinkButton from 'components/LinkButton';
import parseAnchor from 'utils/parseAnchor';

import { Modifiers, TeaserProps } from './models';

import './Teaser.scss';
import './ShadowTeaser.scss';

export const Teaser = ({
  secondaryNavigationAnchor,
  description,
  disclaimer,
  cta: [cta],
  ctaAriaLabel,
  title,
  alt,
  designStyle,
  imagePosition,
  teaserImage,
}: TeaserProps) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);
  const modifier: Modifiers = designStyle.split('+').reduce((prev, current) => {
    const keyAndValue = current.split(':');
    const newObject = {
      ...prev,
      [keyAndValue[0].trim()]: keyAndValue[1].trim(),
    };

    return newObject;
  }, {});

  return (
    <Row
      data-testid="teaser"
      id={anchorParsed}
      className={classNames('teaser', { [`teaser--${modifier.bg}`]: modifier.bg })}
    >
      <div
        className={classNames('teaser__information-section', {
          [`teaser__information-section--${imagePosition}`]: imagePosition,
        })}
        data-testid="teaser-information-section"
      >
        <Col className="teaser__information">
          <h2
            className={classNames('teaser__title', {
              [`teaser__title--${modifier.text}`]: modifier.text,
            })}
          >
            {title}
          </h2>
          <DangerouslySetInnerHtml
            html={description}
            className={classNames('teaser__description', {
              [`teaser__description--${modifier.text}`]: modifier.text,
            })}
            data-testid="teaser-description"
          />
          {cta ? (
            <div className="teaser__links">
              <LinkButton
                text={cta.name}
                type={VisualType.Discover}
                url={cta.url}
                ariaLabel={ctaAriaLabel}
                variant={modifier.bg === 'white' ? VisualVariant.Dark : VisualVariant.Light}
              />
            </div>
          ) : null}
          {disclaimer ? (
            <div
              className={classNames('teaser__disclaimer', {
                [`teaser__disclaimer--${modifier.bg}`]: modifier.bg,
              })}
              data-testid="disclaimer-section"
            >
              <DangerouslySetInnerHtml html={disclaimer} />
            </div>
          ) : null}
        </Col>
      </div>

      <div
        className={classNames('teaser__image-section', {
          [`teaser__image-section--${modifier.bg}`]: modifier.bg,
          [`teaser__image-section--${imagePosition}`]: imagePosition,
        })}
        data-testid="teaser-image-section"
      >
        <div className="teaser__image">
          <BannerImage image={teaserImage} alt={alt} />
        </div>
      </div>
    </Row>
  );
};

export default Teaser;
