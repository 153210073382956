import React, { FC } from 'react';

import Image from 'common/Image';

import { BannerImageProps } from '../models.d';

const BannerImage: FC<BannerImageProps> = ({ image, alt }) => {
  if (!image) {
    return null;
  }

  return image.imageCropper ? (
    <Image
      imageData={image.imageCropper}
      alt={alt}
      loading="eager"
      data-testid="banner-full-width-image"
    />
  ) : image.imagePicker ? (
    <Image
      imageData={image.imagePicker}
      alt={alt}
      objectFit="scale-down"
      data-testid="banner-packshot-image"
    />
  ) : null;
};

export default BannerImage;
