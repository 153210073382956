import React from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';

import { MobileNavItemProps } from '../models';

export const NavItem = ({
  item,
  isOpen,
  navItemClick,
  linkClick,
  seeAllLabel,
}: MobileNavItemProps) => {
  const hasSublevel = item.properties.subItem?.length;
  const itemClassNames = classNames('mobile-nav-item__item', {
    [`mobile-nav-item__item--with-sublevel`]: hasSublevel,
    [`mobile-nav-item__item--active`]: isOpen,
    [`mobile-nav-item__item--${item.properties?.type}`]: item.properties?.type,
  });
  const sublevelClassNames = classNames('mobile-nav-item__sublevel', {
    [`mobile-nav-item__sublevel--active`]: isOpen,
  });

  const linkClickHandler = (link) => {
    navigate(link);
    linkClick();
  };

  return (
    <li className="mobile-nav-item" data-testid="mobile-nav-item">
      <div className={itemClassNames}>
        {hasSublevel ? (
          <button
            type="button"
            onClick={navItemClick}
            data-testid={`mobile-nav-item-button-with-sublevels-${kebabCase(item.properties.name)}`}
          >
            {item.properties.name}
          </button>
        ) : (
          <button
            type="button"
            role="link"
            onClick={() => linkClickHandler(item.properties.link && item.properties.link[0].url)}
            data-testid={`mobile-nav-item-button-${kebabCase(item.properties.name)}`}
          >
            {item.properties.name}
          </button>
        )}
      </div>

      {hasSublevel ? (
        <ul className={sublevelClassNames}>
          {item.properties.subItem?.map((navSubItem) => (
            <li className="mobile-nav-item__subitem" key={navSubItem.properties.name}>
              <button
                type="button"
                role="link"
                onClick={() =>
                  linkClickHandler(navSubItem.properties.link && navSubItem.properties.link[0].url)
                }
              >
                {navSubItem.properties.name}
              </button>
            </li>
          ))}

          {item.properties?.dismissSeeAllInSubitems !== '1' ? (
            <li className="mobile-nav-item__subitem">
              <button
                type="button"
                role="link"
                onClick={() =>
                  linkClickHandler(item.properties.link && item.properties.link[0].url)
                }
              >
                {`${seeAllLabel} ${item.properties.name}`}
              </button>
            </li>
          ) : null}
        </ul>
      ) : null}
    </li>
  );
};

export default NavItem;
