import React, { useEffect, useState } from 'react';

import { MobileNavigationProps } from '../models';
import NavItem from './NavItem';

import './MobileNavigation.scss';

export const MobileNavigation = ({
  items,
  closeMenu,
  isMobileMenuShown,
  seeAllLabel,
}: MobileNavigationProps) => {
  const [openItem, setOpenItem] = useState<number | undefined>();

  // Close sublists after mobile navigation is closed
  useEffect(() => {
    if (isMobileMenuShown === false) setOpenItem(undefined);
  }, [isMobileMenuShown]);

  const toggleItem = (index) => {
    index === openItem ? setOpenItem(undefined) : setOpenItem(index);
  };

  const closeMenuHandler = () => {
    setOpenItem(undefined);
    closeMenu();
  };

  return (
    <ul className="mobile-navigation" data-testid="mobile-navigation">
      {items.map((navItem, index) => (
        <NavItem
          item={navItem}
          navItemClick={() => toggleItem(index)}
          isOpen={index === openItem}
          linkClick={closeMenuHandler}
          seeAllLabel={seeAllLabel}
          key={navItem.properties.name}
        />
      ))}
    </ul>
  );
};

export default MobileNavigation;
