import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'gatsby';

import { getPathForLanguage } from 'utils/functions';

import { LanguageSelectorItemProps } from './models';

const LanguageSelectorItem: FC<LanguageSelectorItemProps> = ({ name, langUrls, iso, homePath }) => (
  <Dropdown.Item as="li" eventKey={name} className="language-selector__item">
    <Link className="language-selector__link" to={getPathForLanguage(langUrls, iso, homePath)}>
      {name}
    </Link>
  </Dropdown.Item>
);

export default LanguageSelectorItem;
