import React, { FC, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { getLocationQS } from 'utils/browser';

import { SearchSiteProps } from './models.d';

import './SearchSite.scss';

const SearchSite: FC<SearchSiteProps> = ({
  isVisible,
  isPageScrolledDown,
  setVisible,
  langProps,
}) => {
  const {
    allHeader: { nodes },
    allSharedComponentsSettings: { nodes: sharedNodes },
  } = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          lang
          searchSitePlaceholder
          closeSearchLabel
        }
      }
      allSharedComponentsSettings {
        nodes {
          lang
          simpleSearch {
            searchAriaLabel
            searchResultPageLocation {
              url
            }
          }
        }
      }
    }
  `);
  const { searchSitePlaceholder, closeSearchLabel } = nodes.find(
    ({ lang }) => langProps.lang === lang
  )!;
  const {
    simpleSearch: { searchResultPageLocation, searchAriaLabel },
  } = sharedNodes.find(({ lang }) => langProps.lang === lang)!;

  const [searchValue, setSearchValue] = useState('');
  const query = getLocationQS() || '';

  const handleCloseSearchSite = () => {
    setVisible(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    searchValue && navigate(`${searchResultPageLocation[0].url}?q=${searchValue}`);
  };

  const onInputChange = ({ target: { value } }) => setSearchValue(value);

  const headerClassNames = classNames('search-site', {
    [`search-site--scrolled-down`]: isPageScrolledDown,
  });

  useEffect(() => {
    setSearchValue(query);
    handleCloseSearchSite();
  }, [query]);

  return isVisible ? (
    <div className={headerClassNames} data-testid="search-site">
      <Form onSubmit={handleSubmit} role="search">
        <InputGroup>
          <InputGroup.Prepend>
            <button
              className="search-site__submit"
              onClick={handleSubmit}
              tabIndex={0}
              type="submit"
              aria-label={searchAriaLabel}
            />
          </InputGroup.Prepend>
          <label htmlFor="header-search">
            <span className="visually-hidden">{searchSitePlaceholder}</span>
          </label>
          <FormControl
            placeholder={searchSitePlaceholder}
            aria-label={searchAriaLabel}
            value={searchValue}
            onChange={onInputChange}
            className="search-site__input"
            autoFocus
            id="header-search"
            type="search"
          />
          <InputGroup.Append>
            <button
              onClick={handleCloseSearchSite}
              aria-label={closeSearchLabel}
              data-testid="search-site-close-button"
              className="search-site__close-button"
              type="button"
            >
              <span className="search-site__close-icon" />
            </button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  ) : null;
};

export default SearchSite;
