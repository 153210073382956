const userEventsList = ['scroll', 'mousemove', 'keydown'];
const maxInactivityTime = 4000;
let interacted = false;

const gtmHeadScriptDataLayer = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'gtmHeadScript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GOOGLE_TAG_ID}`;

  document.head.appendChild(script);
};

const addNoScriptDataLayer = () => {
  const gtmNoScript = document.createElement('noscript');
  const gtmIframe = document.createElement('iframe');

  gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_ID}`;
  gtmIframe.height = '0';
  gtmIframe.width = '0';
  gtmIframe.style.display = 'none';
  gtmIframe.style.visibility = 'hidden';
  gtmIframe.id = 'gtmIframe';

  gtmNoScript.appendChild(gtmIframe);

  document.body.prepend(gtmNoScript);
};

const handleGTMinitOnUserInteraction = () => {
  userEventsList.forEach((event) => {
    document.removeEventListener(event, handleGTMinitOnUserInteraction);
  });

  addNoScriptDataLayer();
  gtmHeadScriptDataLayer();

  interacted = true;
};

export const interactionEventStarter = () => {
  const gtmIframeElement = document.getElementById('gtmIframe');
  const gtmHeadScriptElement = document.getElementById('gtmHeadScript');

  if (!gtmHeadScriptElement && !gtmIframeElement) {
    userEventsList.forEach((event) => {
      document.addEventListener(event, handleGTMinitOnUserInteraction);
    });

    const timer = setTimeout(() => {
      if (!interacted) {
        handleGTMinitOnUserInteraction();
        clearTimeout(timer);
      }
    }, maxInactivityTime);
  }
};

export default {
  interactionEventStarter,
};
