import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql, useStaticQuery } from 'gatsby';

import BreadcrumbItem from 'common/Breadcrumbs/BreadcrumbItem';

import { BreadcrumbsProps } from './models';

import './Breadcrumbs.scss';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const {
    sharedComponentsSettings: { breadcrumbAriaLabel },
    languageSelector: { languages },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        breadcrumbAriaLabel
      }
      languageSelector {
        languages {
          path
        }
      }
    }
  `);

  const filteredBreadcrumbs =
    breadcrumbs[1] && languages.some(({ path }) => path === breadcrumbs[1].pathname)
      ? breadcrumbs.slice(1)
      : breadcrumbs;

  return (
    <Row data-testid="breadcrumbs" className="breadcrumbs">
      <Col as="nav" aria-label={breadcrumbAriaLabel}>
        <ol>
          {filteredBreadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem
              key={breadcrumb.crumbLabel}
              {...breadcrumb}
              current={index === filteredBreadcrumbs.length - 1}
            />
          ))}
        </ol>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;

export const query = graphql`
  fragment FragmentAllPageSite on SitePageConnection {
    nodes {
      context {
        breadcrumb {
          location
          crumbs {
            pathname
            crumbLabel
          }
        }
        areBreadcrumbsDisplayed
        breadcrumbName
      }
    }
  }
`;
