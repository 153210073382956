import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { HamburgerProps, HamburgerQuery } from './models';

import './Hamburger.scss';

export const Hamburger = ({ toggleMenu, isMobileMenuShown }: HamburgerProps) => {
  const {
    header: { hamburgerAriaLabel },
  }: HamburgerQuery = useStaticQuery(graphql`
    {
      header {
        hamburgerAriaLabel
      }
    }
  `);
  const hamburgerClassNames = classNames('hamburger', { 'hamburger--open': isMobileMenuShown });

  return (
    <button
      className={hamburgerClassNames}
      aria-label={hamburgerAriaLabel}
      type="button"
      onClick={toggleMenu}
      data-testid="hamburger"
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default Hamburger;
