import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import DropdownLanguage from 'components/DropdownLocation';
import dropdownLocationProps from 'components/DropdownLocation/mock.json';
import { DropdownLocationProps } from 'components/DropdownLocation/models.d';
import SocialLinks from 'components/Social';

import { IFooterContentProps } from './models';

export const FooterContent: FC<IFooterContentProps> = ({
  navbar,
  additionalLinks,
  additionalLinksTitle,
  socialLinks,
  copyrightNotice,
  footerType,
}) => (
  <div
    className={classNames('footer__footer-content', {
      [`footer__footer-content--${footerType?.[0]?.type}`]: footerType?.[0]?.type,
    })}
  >
    <div className="footer__nav">
      <ul>
        {navbar.map(({ properties }) => {
          const { label, url } = properties;
          const [footerItem] = url;
          const [key] = useId();

          return (
            <li className="footer__nav--link-box" key={key}>
              <Link
                to={footerItem.url}
                className="footer__link"
                data-testid="footer-link"
                target={footerItem.target}
                {...(footerItem.target && { rel: 'noopener noreferrer' })}
              >
                {label}
              </Link>
            </li>
          );
        })}
      </ul>

      {additionalLinks?.length ? (
        <div className="footer__nav--additional-links">
          {additionalLinksTitle ? (
            <p className="footer__nav--additional-title">{additionalLinksTitle}</p>
          ) : null}
          <ul className="footer__nav--additional-list">
            {additionalLinks.map(({ properties }) => {
              const { label, url } = properties;
              const [footerItem] = url;
              const [key] = useId();

              return (
                <li className="footer__nav--link-box" key={key}>
                  <Link
                    to={footerItem.url}
                    className="footer__link"
                    data-testid="additional-footer-link"
                    target={footerItem.target}
                    {...(footerItem.target && { rel: 'noopener noreferrer' })}
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      <div className="footer__link--lang-selector">
        {/* TODO remove 'false' condition and '<div />' element after Country Selector return in this place in phase 2 */}
        {false && <DropdownLanguage {...(dropdownLocationProps as DropdownLocationProps)} />}
        <SocialLinks socialLinks={socialLinks} />
      </div>
    </div>

    <div className="footer__copyright">
      <DangerouslySetInnerHtml html={copyrightNotice} />
    </div>
  </div>
);

export default FooterContent;
