import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SearchButtonProps } from './models';

import './SearchButton.scss';

const SearchButton: FC<SearchButtonProps> = ({
  langProps,
  isSiteSearchVisible,
  toggleSiteSearch,
}) => {
  const {
    allHeader: { nodes },
  } = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          lang
          openSearchLabel
          closeSearchLabel
        }
      }
    }
  `);
  const { openSearchLabel, closeSearchLabel } = nodes.find(({ lang }) => langProps.lang === lang)!;

  return (
    <button
      data-testid="search-site-button"
      type="button"
      onClick={toggleSiteSearch}
      className="search-site-button"
      aria-pressed={isSiteSearchVisible}
      aria-label={isSiteSearchVisible ? closeSearchLabel : openSearchLabel}
    />
  );
};

export default SearchButton;
