import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql, useStaticQuery } from 'gatsby';

import { VisualType, VisualVariant } from 'common/Button';
import LinkButton from 'components/LinkButton';
import { getSessionStorageData, setSessionStorageData } from 'utils/storage';

import { AlertProps, AlertQuery } from './models';

import './Alert.scss';

const Alert = ({ langProps }: AlertProps) => {
  const {
    allAlert: { nodes },
  }: AlertQuery = useStaticQuery(graphql`
    {
      allAlert {
        nodes {
          lang
          link {
            url {
              url
              name
            }
            text
            ariaLabel
          }
          name
          title
          description
          closeButtonAriaLabel
        }
      }
    }
  `);
  const [isVisible, setVisible] = useState<boolean>(false);
  const alert = nodes.find(({ lang }) => langProps.lang === lang)!;

  if (!alert) {
    return null;
  }

  const { title, description, name = 'DEFAULT_ALERT', link = [], closeButtonAriaLabel } = alert;

  const handleCloseAlert = () => {
    setVisible(false);
    setSessionStorageData(name, 'isClosed');
  };

  useEffect(() => {
    const isAlertCustomClosed = getSessionStorageData(name);
    setVisible(!isAlertCustomClosed);
  }, []);

  const buttonLink = link[0];

  return isVisible ? (
    <Row className="alert-custom-wrapper">
      <Col>
        <div className="alert-custom" data-testid="alert-box">
          <button
            onClick={handleCloseAlert}
            aria-label={closeButtonAriaLabel}
            className="alert-custom__close-button"
            type="button"
          >
            <span className="alert-custom__close-icon" />
          </button>
          <div className="alert-custom__content">
            <div className="alert-custom__title">{title}</div>
            <div className="alert-custom__description">{description}</div>
            {buttonLink ? (
              <LinkButton
                text={buttonLink.text}
                type={VisualType.Discover}
                url={buttonLink.url?.[0]?.url}
                ariaLabel={buttonLink.ariaLabel}
                variant={VisualVariant.Dark}
              />
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  ) : null;
};

export default Alert;
