const NL_FORM_STATUS = 'nl_form_status';
const STATUS_POSTPONED = 'postponed';

const SHORT_DELAY_DEFAULT = 3600;
const LONG_DELAY_DEFAULT = 31536e3;

const TIME_TO_SHOW_DEFAULT = 3000;

const DELAY_MULTIPLIER = 3600;
const TIMEOUT_MULTIPLIER = 1000;

const LOCK_SCROLL = 'hidden';
const UNLOCK_SCROLL = 'auto';

const HANDLER_ENDPOINT = '/.netlify/functions/newsletter-sign-up';

const FORM_FIELDS = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DATE: 'dateOfBirth',
};

const initialValues = {
  [FORM_FIELDS.EMAIL]: '',
  [FORM_FIELDS.FIRST_NAME]: '',
  [FORM_FIELDS.LAST_NAME]: '',
  [FORM_FIELDS.DATE]: '',
};

export {
  DELAY_MULTIPLIER,
  FORM_FIELDS,
  HANDLER_ENDPOINT,
  initialValues,
  LOCK_SCROLL,
  LONG_DELAY_DEFAULT,
  NL_FORM_STATUS,
  SHORT_DELAY_DEFAULT,
  STATUS_POSTPONED,
  TIME_TO_SHOW_DEFAULT,
  TIMEOUT_MULTIPLIER,
  UNLOCK_SCROLL,
};
