import React, { useEffect, useState } from 'react';

import { DesktopNavigationProps } from '../models';
import NavItem from './NavItem';

import './DesktopNavigation.scss';

export const DesktopNavigation = ({
  items,
  lastTwoBreakingPoint,
  moreButtonLabel,
  seeAllLabel,
}: DesktopNavigationProps) => {
  const [hiddenItemsCount, setHiddenItemsCount] = useState<number>(0);
  const [openItem, setOpenItem] = useState<number | undefined>();

  const abstractValueForMoreButton = 1000;
  const moreItem = {
    properties: {
      name: moreButtonLabel,
      subItem: items.slice(items.length - hiddenItemsCount),
    },
  };

  const toggleItemSublevel = (index, isKeyEvent) =>
    index === openItem || !isKeyEvent ? setOpenItem(undefined) : setOpenItem(index);

  const checkNavSize = () => {
    if (lastTwoBreakingPoint && lastTwoBreakingPoint >= window.innerWidth) {
      setHiddenItemsCount(2);
    } else {
      setHiddenItemsCount(0);
    }
  };

  useEffect(() => {
    checkNavSize();
    window.addEventListener('resize', checkNavSize);

    return () => window.removeEventListener('resize', checkNavSize);
  }, []);

  return (
    <ul className="desktop-navigation" data-testid="desktop-navigation">
      {items.slice(0, items?.length - hiddenItemsCount).map((navItem, index) => {
        // Home button hidden on desktop
        if (index === 0) {
          return false;
        }

        return (
          <NavItem
            item={navItem}
            seeAllLabel={seeAllLabel}
            isOpen={openItem === index}
            toggleIsOpen={(isKeyEvent = false) => toggleItemSublevel(index, isKeyEvent)}
            key={navItem.properties.name}
            closeMenu={() => setOpenItem(undefined)}
          />
        );
      })}

      {hiddenItemsCount !== 0 ? (
        <NavItem
          item={moreItem}
          seeAllLabel={seeAllLabel}
          isOpen={openItem === abstractValueForMoreButton}
          closeMenu={() => setOpenItem(undefined)}
          toggleIsOpen={(isKeyEvent = false) =>
            toggleItemSublevel(abstractValueForMoreButton, isKeyEvent)
          }
        />
      ) : null}
    </ul>
  );
};

export default DesktopNavigation;
