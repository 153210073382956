import React, { FC, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { graphql, useStaticQuery } from 'gatsby';

import LanguageSelectorItem from './LanguageSelectorItem';
import { LanguageSelectorProps } from './models';

import './LanguageSelector.scss';

const LanguageSelector: FC<LanguageSelectorProps> = ({ langProps }) => {
  const {
    allLanguageSelector,
  }: {
    allLanguageSelector: { nodes: PageContent.LanguageSelector[] };
  } = useStaticQuery(graphql`
    {
      allLanguageSelector {
        nodes {
          languageTitle
          url
          languages {
            iso
            name
            path
          }
          defaultLang
          lang
        }
      }
    }
  `);
  const [isOpen, setIsOpen] = useState(false);
  const { languages, defaultLang, languageTitle } = allLanguageSelector.nodes.find(
    ({ lang }) => lang === langProps.lang
  )!;

  const langUrls = langProps.urls;
  const lang = langProps.lang || defaultLang;
  const currentLangName = languages.find(({ iso }) => lang === iso.toLowerCase())?.name;

  return languages?.length > 1 ? (
    <section className="language-selector" data-testid="language-selector">
      <span className="language-selector__title">{languageTitle}</span>
      <Dropdown
        className="language-selector__dropdown"
        show={isOpen}
        onToggle={(newIsOpen) => {
          setIsOpen(newIsOpen);
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Dropdown.Toggle className="language-selector__toggle" id="language-selector">
          <span className="language-selector__current">{currentLangName}</span>
          <span className="language-selector__arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="language-selector__list">
          {languages
            .filter(({ iso }) => lang !== iso.toLowerCase())
            .map(({ name, iso, path }) => (
              <LanguageSelectorItem {...{ name, langUrls, iso, homePath: path }} key={name} />
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </section>
  ) : null;
};

export default LanguageSelector;
