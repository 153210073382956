const addScript = `

(function (m, f, i, l, t, e, r) {

m[t] = m[t] || function () {(m[t].q = m[t].q || []).push(arguments)}, m[t].l = 1 * new Date();

e = f.createElement(l); e.async = 1; e.id = "mfilterit-visit-tag"; e.src = i;

r=f.getElementsByTagName(l)[0]; r.parentNode.insertBefore(e, r);

})(window, document,"https://script.mfilterit.net/v3/v/client/web.reckitt_vanish.cpv.js", "script", "mf");

mf("mf_package_name", "web.reckitt_vanish.cpv");

mf("mf_tracking_type", "pageviews");

`;

export default addScript;
