import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql, useStaticQuery } from 'gatsby';

import FooterContent from './FooterContent';
import { FooterProps, FooterQuery } from './models';

import './Footer.scss';
import './FooterOverride.scss';

export const Footer: FC<FooterProps> = ({ langProps, footerType }) => {
  const {
    allFooter: { nodes },
  }: FooterQuery = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          lang
          socialLinks {
            properties {
              url {
                name
                url
              }
              type
            }
          }

          navbar {
            properties {
              label
              url {
                url
                target
              }
            }
          }
          additionalLinks {
            properties {
              label
              url {
                url
                target
              }
            }
          }
          copyright
          additionalLinksTitle
          additionalInfo
        }
      }
    }
  `);
  const {
    navbar,
    copyright,
    socialLinks,
    additionalLinks,
    additionalLinksTitle,
    additionalInfo,
  } = nodes.find(({ lang }) => langProps.lang === lang)!;
  const currentYear = new Date().getFullYear();
  const copyrightNotice = copyright?.replace(/<p>/g, `<p>© ${currentYear} `);

  return (
    <footer className="footer">
      <div className="footer__container">
        <Container fluid="lg">
          <FooterContent
            navbar={navbar}
            socialLinks={socialLinks}
            additionalLinks={additionalLinks}
            additionalLinksTitle={additionalLinksTitle}
            copyrightNotice={copyrightNotice}
            additionalInfo={additionalInfo}
            footerType={footerType}
          />
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
