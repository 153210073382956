import React, { FC } from 'react';

import { SocialLinksProps } from './models';

import './SocialLinks.scss';

export const SocialLinks: FC<SocialLinksProps> = ({ socialLinks }) =>
  socialLinks?.length ? (
    <ul data-testid="social-links" className="social-links">
      {socialLinks.map(({ properties: { type, url } }) => (
        <li key={type[0]}>
          <a
            href={url[0]?.url}
            target="_blank"
            rel="noreferrer noopener"
            aria-label={url[0]?.name} // name in umbraco is used as aria-label
          >
            <span className="accessibility-hidden">{type[0]}</span>
            <span className={`social-icon social-icon--${type[0]?.toLowerCase()}`} />
          </a>
        </li>
      ))}
    </ul>
  ) : null;

export default SocialLinks;
