import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Image from 'common/Image';

import { HeaderQuery, LogoProps } from './models';

import './Logo.scss';

const Logo: FC<LogoProps> = ({ langProps }) => {
  const {
    allHeader: { nodes: headerNodes },
    allBrandSettings: { nodes: brandSettingsNodes },
  }: HeaderQuery = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          lang
          alt
          logo {
            ...LogoImageFragment
          }
        }
      }
      allBrandSettings {
        nodes {
          lang
          url
        }
      }
    }
  `);

  const { logo, alt } = headerNodes.find(({ lang }) => langProps.lang === lang)!;
  const { url } = brandSettingsNodes.find(({ lang }) => langProps.lang === lang)!;

  return (
    <Link to={url} className="logo">
      <Image imageData={logo} alt={alt} className="logo__image" loading="eager" />
    </Link>
  );
};

export const query = graphql`
  fragment LogoImageFragment on ResponsiveImage {
    desktop {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
    tablet {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
    mobile {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
  }
`;

export default Logo;
