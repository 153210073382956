import React, { FC } from 'react';
import Link from 'gatsby-link';

import { buttonClassesHelper } from 'common/Button';

import { LinkButtonProps } from './models';

const LinkButton: FC<LinkButtonProps> = ({
  text,
  ariaLabel,
  url,
  type,
  variant,
  isDisabled = false,
}) => {
  if (!text || !url) {
    return null;
  }

  const isExternalLink = url?.charAt(0) !== '/';
  const buttonClasses = buttonClassesHelper(type, variant, isDisabled);

  return isDisabled ? (
    <div data-testid="button-disabled" className={buttonClasses}>
      {text}
    </div>
  ) : isExternalLink ? (
    <a
      className={buttonClasses}
      href={url}
      aria-label={ariaLabel || undefined}
      target="_blank"
      rel="noopener noreferrer"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <Link
      className={buttonClasses}
      aria-label={ariaLabel || undefined}
      to={url}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default LinkButton;
