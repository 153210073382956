import classNames from 'classnames';

import { VisualType, VisualVariant } from './enums';

const buttonClassesHelper = (
  type: VisualType,
  variant: VisualVariant,
  isDisabled: boolean
): string =>
  classNames(`button button__${type} button__${type}--${variant}`, {
    'button--disabled': isDisabled,
  });

export default buttonClassesHelper;
