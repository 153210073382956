import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import Logo from 'common/Logo';
import Hamburger from 'components/Hamburger';
import LanguageSelector from 'components/LanguageSelector';
import Navigation from 'components/Navigation';
import SearchButton from 'components/SearchButton';
import SearchSite from 'components/SearchSite';

import { HeaderProps } from './models';

import './Header.scss';

export const Header: FC<HeaderProps> = ({ langProps }) => {
  const [isMobileMenuShown, setMobileMenuShown] = useState(false);
  const [isPageScrolledDown, setPageScrolledDown] = useState(false);
  const [isSiteSearchVisible, setSiteSearchVisible] = useState(false);

  useEffect(() => {
    const checkPageScrollPosition = () => setPageScrolledDown(window.pageYOffset !== 0);
    window.addEventListener('scroll', checkPageScrollPosition);

    return () => {
      window.removeEventListener('scroll', checkPageScrollPosition);
    };
  });

  const toggleMenu = () => {
    setMobileMenuShown(!isMobileMenuShown);
  };
  const closeNav = () => setMobileMenuShown(false);
  const toggleSiteSearch = () => setSiteSearchVisible(!isSiteSearchVisible);

  const headerClassNames = classNames('header', {
    [`header--scrolled-down`]: isPageScrolledDown,
  });
  const headerContainerClassNames = classNames('header__container', {
    [`header__container--scrolled-down`]: isPageScrolledDown,
  });
  const headerMainClassNames = classNames('header__main', {
    [`header__main--active`]: isMobileMenuShown,
  });

  return (
    <>
      <header data-testid="header" className={headerContainerClassNames}>
        <div className={headerClassNames}>
          <div className="header__nav-button">
            <Hamburger toggleMenu={toggleMenu} isMobileMenuShown={isMobileMenuShown} />
          </div>

          <div className="header__logo">
            <Logo langProps={langProps} />
          </div>

          <div className={headerMainClassNames}>
            <div className="header__top">
              <LanguageSelector langProps={langProps} />
            </div>

            <nav className="header__bottom">
              <Navigation
                langProps={langProps}
                closeMenu={closeNav}
                isMobileMenuShown={isMobileMenuShown}
              />
            </nav>
          </div>

          <div className="header__search" role="search">
            <SearchButton {...{ langProps, isSiteSearchVisible, toggleSiteSearch }} />
          </div>
        </div>
      </header>
      <SearchSite
        langProps={langProps}
        isVisible={isSiteSearchVisible}
        setVisible={setSiteSearchVisible}
        isPageScrolledDown={isPageScrolledDown}
      />
    </>
  );
};

export default Header;
