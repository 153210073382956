export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const getPathForLanguage = (
  langUrls: Component.LangUrlProps[],
  iso: string,
  homePath: string
): string => langUrls.find(({ lang }) => lang === iso)?.url || homePath;

const utilFunctions = {
  isEmpty,
  getPathForLanguage,
};

export default utilFunctions;
