import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { OG_PAGE_TYPES } from './constants';
import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({
  seo: {
    title,
    description,
    keywords,
    externalHreflangs,
    additionalMeta,
    imageUrl,
    seoOGImage,
    seoOGType,
  },
  langProps,
  pagePathname,
}) => {
  const {
    allSiteSettings: { nodes: siteSettingsNodes },
    allBrandSettings: { nodes: brandSettingsNodes },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query SettingsQuery {
      allSiteSettings {
        nodes {
          siteName
          siteUrl
          lang
          hideSelfHref
        }
      }
      allBrandSettings {
        nodes {
          lang
          brandName
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteSettings = siteSettingsNodes.find(({ lang }) => langProps.lang === lang)!;
  const brandSettings = brandSettingsNodes.find(({ lang }) => langProps.lang === lang)!;
  const siteAuthor = brandSettings.brandName;
  const siteLang = langProps.lang || siteSettings.lang;
  const isHideSelfHref = siteSettings?.hideSelfHref === '1';
  const origin = siteSettings.siteUrl.endsWith('/')
    ? siteSettings.siteUrl.slice(0, -1)
    : siteSettings.siteUrl;

  const meta = [
    {
      name: 'robots',
      content:
        process.env.GATSBY_ACTIVE_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:url',
      content: `${origin}${pagePathname}`,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: seoOGType || OG_PAGE_TYPES.WEBSITE,
    },
    ...(imageUrl || seoOGImage
      ? [
          {
            property: 'og:image',
            content: seoOGImage || imageUrl,
          },
        ]
      : []),
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    ...(imageUrl
      ? [
          {
            property: 'twitter:image',
            content: imageUrl,
          },
        ]
      : []),
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
    ...(process.env.GATSBY_YANDEX_WEBMASTER_KEY
      ? [
          {
            name: 'yandex-verification',
            content: process.env.GATSBY_YANDEX_WEBMASTER_KEY,
          },
        ]
      : []),
    ...additionalMeta.map(({ key, value }) => ({ name: key, content: value })),
  ];

  let link = [
    ...(langProps.urls
      ? langProps.urls.map(({ lang, url }) => ({
          rel: 'alternate',
          href: `${siteUrl}${url}`,
          hreflang: lang.replace('_', '-'),
        }))
      : []),
    ...externalHreflangs.map(({ key, value }) => ({
      rel: 'alternate',
      href: value,
      hreflang: key,
    })),
  ];

  if (isHideSelfHref) {
    link = link.filter((item) => item.hreflang !== langProps.lang);
  }

  return <Helmet htmlAttributes={{ lang: siteLang }} {...{ title, meta, link }} />;
};

export default Seo;
