import { LocalStorageData } from './models';

export const getStorageData = (key: string): LocalStorageData => {
  const data = localStorage.getItem(key);

  return data ? JSON.parse(data) : null;
};

export const getSessionStorageData = (key: string): LocalStorageData => {
  const data = sessionStorage.getItem(key);

  return data ? JSON.parse(data) : null;
};

export const setSessionStorageData = (key: string, value: any): void =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const setStorageData = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const removeStorageData = (key: string): void => localStorage.removeItem(key);

export default {
  getStorageData,
  setStorageData,
  removeStorageData,
  getSessionStorageData,
  setSessionStorageData,
};
