import React, { FC } from 'react';

import buttonClassesHelper from './buttonClassesHelper';
import { ButtonProps } from './models';

const Button: FC<ButtonProps> = ({
  onClick,
  visualType,
  visualVariant,
  isDisabled = false,
  children,
  ariaLabel,
  ref,
  type = 'button',
}) => {
  const buttonClasses = buttonClassesHelper(visualType, visualVariant, isDisabled);

  return (
    <button
      {...{
        'aria-label': ariaLabel || undefined,
        className: buttonClasses,
        ...(isDisabled && { disabled: isDisabled }),
        onClick,
        ref,
        // eslint-disable-next-line react/button-has-type
        type,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
