import React, { FC, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import '../../../../../gatsby-theme-husky/src/styles/main.scss';
import '../../../../../gatsby-theme-husky/src/layout/Layout/Layout.scss';

import Breadcrumbs from '../../../../../gatsby-theme-husky/src/common/Breadcrumbs';
import Seo from '../../../../../gatsby-theme-husky/src/common/Seo';
import StructuredPageSchema from '../../../../../gatsby-theme-husky/src/common/StructuredPageSchema';
import Alert from '../../../../../gatsby-theme-husky/src/components/Alert';
import Footer from '../../../../../gatsby-theme-husky/src/components/Footer';
import Header from '../../../../../gatsby-theme-husky/src/components/Header';
import Loadable from '../../../../../gatsby-theme-husky/src/components/Loadable';
import { NL_FORM_STATUS } from '../../../../../gatsby-theme-husky/src/components/NewsletterPopup/constants';
import WhatsAppWidget from '../../../../../gatsby-theme-husky/src/components/WhatsAppWidget';
import { DangerouslySetInnerHtml } from '../../../../../gatsby-theme-husky/src/layout';
import { LayoutProps } from '../../../../../gatsby-theme-husky/src/layout/Layout/models';
import { isBrowser } from '../../../../../gatsby-theme-husky/src/utils/browser';
import cookieUtils from '../../../../../gatsby-theme-husky/src/utils/cookie';
import { interactionEventStarter } from '../../../../../gatsby-theme-husky/src/utils/gtmTimeoutUtils';
import { schemaService } from '../../../../../gatsby-theme-husky/src/utils/schemaService';
import addScript from './helpers';

const PreviewWidget = loadable(
  () => import('../../../../../gatsby-theme-husky/src/components/PreviewWidget')
);
const NewsletterPopup = loadable(
  () => import('../../../../../gatsby-theme-husky/src/components/NewsletterPopup')
);

const Layout: FC<LayoutProps> = ({
  children,
  breadcrumbs,
  className,
  seo,
  langProps,
  pagePathname,
  additionalSchema,
  schemaImageUrl,
}) => {
  const {
    allSiteSettings: { nodes: siteSettingsNodes },
    allBrandSettings: { nodes: brandSettingsNodes },
  } = useStaticQuery(graphql`
    {
      allSiteSettings {
        nodes {
          showNewsletterPopup
          lang
          siteUrl
          siteName
        }
      }
      allBrandSettings {
        nodes {
          lang
          brandName
          brandLogo {
            fallbackUrl
          }
          youtubeUrl
          facebookUrl
        }
      }
    }
  `);

  const siteSettings = siteSettingsNodes.find(({ lang }) => langProps.lang === lang) || {};
  const brandSettings = brandSettingsNodes.find(({ lang }) => langProps.lang === lang) || {};
  const schemaData = schemaService.extractSchemaData({
    seo,
    pagePathname,
    siteSettings,
    brandSettings,
    lang: langProps.lang,
    schemaImageUrl,
  });
  const CSSclassNames = classNames('layout', className);
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));
  const showNewsletterPopup =
    isBrowser() &&
    !cookieUtils.getCookie(NL_FORM_STATUS) &&
    Boolean(parseInt(siteSettings.showNewsletterPopup, 10));

  useEffect(() => {
    isBrowser() && window?.dataLayer && interactionEventStarter();
  }, []);

  return (
    <div className={CSSclassNames} data-testid="layout">
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      {seo ? <Seo {...{ seo, langProps, pagePathname }} /> : null}
      <StructuredPageSchema additionalSchema={additionalSchema} schemaData={schemaData} />

      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />

      <Header {...{ langProps }} />
      <DangerouslySetInnerHtml element="script" html={addScript} />
      <Container as="main" className="layout__main" fluid="lg">
        <Alert {...{ langProps }} />
        <WhatsAppWidget {...{ langProps }} />

        {breadcrumbs?.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}

        {children}
      </Container>
      <Loadable isActive={showNewsletterPopup}>
        <NewsletterPopup actualLang={langProps.lang} />
      </Loadable>
      <Footer {...{ langProps }} />
    </div>
  );
};

export default Layout;
