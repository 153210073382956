export interface ICookieOptions {
  path: string;
  'max-age': number;
  secure: boolean;
}

export interface ICookieUtils {
  getCookie: (name: string) => string | undefined;
  setCookie: (name: string, value: string, options: Partial<ICookieOptions>) => void;
  deleteCookie: (name: string) => void;
}

class CookieUtils implements ICookieUtils {
  getCookie(name: string): string | undefined {
    const matches = document.cookie.match(
      // eslint-disable-next-line no-useless-escape
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name: string, value: string, opt: Partial<ICookieOptions> = {}): void {
    const options = {
      path: '/',
      ...opt,
    };

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    Object.entries(options).forEach(([key, val]) => {
      updatedCookie += `; ${key}${val !== true ? `=${val}` : ''}`;
    });

    document.cookie = updatedCookie;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', { 'max-age': -1 });
  }
}

export default new CookieUtils();
